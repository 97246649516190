import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const Step = ({ image, number, title, description = '' }) => {
  let background = 'turquoise';

  if (number % 4 === 1) {
    background = 'teal';
  } else if (number % 4 === 2) {
    background = 'deep-turquoise';
  } else if (number % 4 === 3) {
    background = 'green';
  }

  return (
    <div
      className={`step ${styles.step} step--bg-${background} ${
        number % 2 === 0 ? 'step--even' : 'step--odd'
      }`}
    >
      <div className="wrapper">
        <div className="col-first">
          <div
            className="image"
            style={{ backgroundImage: `url(${image.url})` }}
            title={image.alt}
          />
        </div>
        <div className="col-last">
          <div className="content">
            <div className="number">{number}</div>
            <h3 className="title">{title}</h3>

            {description && <div className="description">{description}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

const propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Step.propTypes = propTypes;

export { propTypes };

export default Step;
