import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const ActivismBlockInfo = ({ title = '', subtitle = '', size = 'normal' }) => {
  if (!title && !subtitle) {
    return null;
  }
  return (
    <div className={styles['activism-block-info']}>
      <div className="container">
        {title && (
          <h2
            className={`${styles['activism-block-info__title']} ${
              styles[`activism-block-info__title--${size}`]
            }`}
          >
            {title}
          </h2>
        )}
        {subtitle && (
          <div
            className={`${styles['activism-block-info__subtitle']} ${
              styles[`activism-block-info__subtitle--${size}`]
            }`}
          >
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};

ActivismBlockInfo.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'large']),
};

export default ActivismBlockInfo;
