import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useOnScrollAnimation from '../../../utils/animationImageOnScroll';
import DefaultPicture, { propTypes as picturePropTypes } from '../../01_atoms/DefaultPicture';
import BulletPointBackgroundIcon from '../../../public/static/icons/bullet-point-background.svg';
import Link, { propTypes as linkPropTypes } from '../../01_atoms/Link';
import TextButton from '../../01_atoms/TextButton';

import styles from './index.module.scss';

// The values for the first and last frames of animation.
const animationConfig = {
  1: {
    angleInitial: 6.83,
    angleFinished: 9.36,
    yInitial: 187,
    yFinished: 0,
    xInitial: 20,
    xFinished: 0,
    angleInitialMob: 6.33,
    angleFinishedMob: 9.36,
    yInitialMob: 88,
    yFinishedMob: 0,
    breakpoint: 992,
  },
  2: {
    angleInitial: -10,
    angleFinished: -8.7,
    yInitial: 171,
    yFinished: 0,
    xInitial: -35,
    xFinished: 0,
    angleInitialMob: -6.33,
    angleFinishedMob: -9.36,
    yInitialMob: 77,
    yFinishedMob: 0,
    breakpoint: 992,
  },
  3: {
    angleInitial: -9.4,
    angleFinished: -6.91,
    yInitial: 188,
    yFinished: 0,
    xInitial: 5,
    xFinished: 0,
    angleInitialMob: -9.5,
    angleFinishedMob: -6.91,
    yInitialMob: 76,
    yFinishedMob: 0,
    breakpoint: 992,
  },
  4: {
    angleInitial: -5.5,
    angleFinished: -1.77,
    yInitial: 160,
    yFinished: 0,
    xInitial: 0,
    xFinished: 0,
    angleInitialMob: -6.11,
    angleFinishedMob: -3.11,
    yInitialMob: 66,
    yFinishedMob: 0,
    breakpoint: 992,
  },
};

const StepActivism = ({ image, icon = null, number, title, description = '', button = null }) => {
  const refImage = useRef(null);
  const stepType = ((number - 1) % 4) + 1;
  const [animationTransformStyle] = useOnScrollAnimation(refImage, animationConfig[stepType]);

  const classes = [
    styles.step,
    styles[`step--type-${stepType}`],
    number % 2 === 0 ? styles['step--even'] : styles['step--odd'],
  ];

  return (
    <div className={classes.join(' ')}>
      <div className={styles['step__content']}>
        <div className={styles['step__image-group']}>
          <div className={styles['step__image']} ref={refImage} style={animationTransformStyle}>
            <DefaultPicture {...image} />
          </div>
        </div>

        <div className={styles['step__info-group']}>
          <div className={styles['step__info']}>
            <div
              className={`${styles['step__bullet']} ${
                styles[`step__bullet--${icon?.url ? 'icon' : 'number'}`]
              }`}
            >
              {icon?.url ? (
                <img src={icon.url} alt={icon.alt} />
              ) : (
                <>
                  <BulletPointBackgroundIcon className={styles['step__number-bg']} />
                  {number}
                </>
              )}
            </div>

            <h3 className={styles['step__title']}>{title}</h3>

            {description && <div className={styles['step__description']}>{description}</div>}

            {button?.nextLink && button.label && (
              <div className={styles['step__action']}>
                <Link {...button.nextLink}>
                  <TextButton withArrow>{button.label}</TextButton>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const propTypes = {
  image: PropTypes.shape(picturePropTypes).isRequired,
  icon: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
};

StepActivism.propTypes = propTypes;

export { propTypes };

export default StepActivism;
