import { useState, useEffect, useLayoutEffect } from 'react';

const useOnScrollAnimation = (reference, animationConfig) => {
  const [percent, setPercent] = useState(0);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const imageTop = reference.current.getBoundingClientRect().top;
    // Element coordinates are related to the viewport. For example,
    // if you reload the page when you are somewhere in the middle,
    // coordinates will be different than if you just open the page.
    // So they need to be calculated in relation to the body element.
    const bodyTop = document.body.getBoundingClientRect().top;
    const imageTopAbsolute = imageTop - bodyTop;

    const onScroll = () => {
      const scrollTop = window.scrollY;
      const scrollHeight = window.innerHeight;

      // Window bottom position where animation should start.
      const scrollBottom = scrollTop + scrollHeight;
      // Height from the top of the window where animation should stop.
      const animationStopInterval = scrollHeight / 2;
      // Animation timeline, basically the difference between start and stop positions.
      const animationDuration = scrollHeight - animationStopInterval;

      // Calculating a value that will determine changes between frames.
      let animationPercent = ((scrollBottom - imageTopAbsolute) / animationDuration) * 100;

      if (animationPercent > 100) {
        animationPercent = 100;
      }
      if (animationPercent < 0) {
        animationPercent = 0;
      }

      setPercent(animationPercent);
    };

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, [reference]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const getAngle = () =>
    width >= animationConfig.breakpoint
      ? ((animationConfig.angleFinished - animationConfig.angleInitial) / 100) * percent +
        animationConfig.angleInitial
      : ((animationConfig.angleFinishedMob - animationConfig.angleInitialMob) / 100) *
          percent +
        animationConfig.angleInitialMob;

  const getYShift = () =>
    width >= animationConfig.breakpoint
      ? ((animationConfig.yFinished - animationConfig.yInitial) / 100) * percent +
        animationConfig.yInitial
      : ((animationConfig.yFinishedMob - animationConfig.yInitialMob) / 100) * percent +
        animationConfig.yInitialMob;

  // On mobiles we don't need shift on X axis.
  const getXShift = () =>
    width >= animationConfig.breakpoint
      ? ((animationConfig.xFinished - animationConfig.xInitial) / 100) * percent +
        animationConfig.xInitial
      : 0;

  return [{ transform: `translate(${getXShift()}px, ${getYShift()}px) rotate(${getAngle()}deg)` }];
};

export default useOnScrollAnimation;
