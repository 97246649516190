import React from 'react';
import PropTypes from 'prop-types';
import ActivismBlockInfo from '../../../02_molecules/ActivismBlockInfo';
import Step, { propTypes as StepPropTypes } from '../../../02_molecules/Step';
import StepActivism, {
  propTypes as StepActivismPropTypes,
} from '../../../02_molecules/StepActivism';
import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBMultipleSteps = ({
  heading = '',
  subheading = '',
  title = '',
  items = [],
  variant = 'default',
  behaviorSettings = null,
  uuid = null,
}) => {
  const classes = [
    'bb',
    'bb-multiple-steps',
    styles['bb-multiple-steps'],
    styles[`bb-multiple-steps--variant-${variant}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div className={classes.join(' ')} id={uuid}>
      <ActivismBlockInfo title={heading} subtitle={subheading} size="large" />

      {title && (
        <div className={`container ${styles['bb-multiple-steps__header']}`}>
          <h2 className={styles['bb-multiple-steps__title']}>{title}</h2>
        </div>
      )}

      <div
        className={`${styles['bb-multiple-steps__items-list']} ${
          variant === 'activism' ? 'container' : ''
        }`}
      >
        {items.map((item) => (
          <React.Fragment key={item.number}>
            {variant === 'activism' ? <StepActivism {...item} /> : <Step {...item} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

BBMultipleSteps.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(StepPropTypes), PropTypes.shape(StepActivismPropTypes)]),
  ),
  variant: PropTypes.oneOf(['default', 'activism']),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBMultipleSteps;
